import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useWeb3React } from '@web3-react/core'
import styles from './styles.module.scss'
import TokenSaleService from '../../API/TokenSaleService'
import CustomInputUi from '../../components/UI/CustomInputUi'
import useFormValidator from '../../hooks/useFormValidator'
import tokenSaleValidator from '../../validators/tokenSaleValidator'
import ThumbnailUploader from 'components/ThumbnailUploader'
import ImagesUploader from 'components/ImagesUploader'
import DocumentsUploader from 'components/DocumentsUploader'
import { useIsWalletConnected } from 'state/user/hooks'

const RequestTokenSale = () => {
  const { inputErrors, setInputErrors, isFormValid } =
    useFormValidator(tokenSaleValidator)
  const { account } = useWeb3React()
  console.log('Address: ', account)
  const [formState, setFormState] = useState({
    title: '',
    description: '',
    address: '',
    city: '',
    country: '',
    tokenTotalSupply: '100', // Defualt value: To be update in admin panel before tokensale deployment to the chain
    proposedSalePrice: '',
    tokenPrice: '50', // Defualt value: To be update in admin panel before tokensale deployment to the chain
    saleMinBuy: '1', // Defualt value: To be update in admin panel before tokensale deployment to the chain
    saleMaxBuy: '100', // Defualt value: To be update in admin panel before tokensale deployment to the chain
    saleStartAt: '',
    saleEndAt: '',
    rentAmount: '1', // Defualt value: To be update in admin panel before tokensale deployment to the chain
    projectedAnnualReturn: '3', // Defualt value: To be update in admin panel before tokensale deployment to the chain
    projectedRentalYield: '3', // Defualt value: To be update in admin panel before tokensale deployment to the chain
    rentalYield: '3', // Defualt value: To be update in admin panel before tokensale deployment to the chain
    houseEquityOwned: '',
  })

  const [musharakahAllowed, setMusharakahAllowed] = useState(false)

  const [isRefinancing, setIsRefinancing] = useState(false) // New state for refinancing

  const [thumbnail, setThumbnail] = useState(null)
  const [images, setImages] = useState([])
  const [documents, setDocuments] = useState([])

  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState('')

  const navigate = useNavigate()

  const handleInputChange = ({ target }) => {
    const { name, value } = target
    setFormState((prevData) => ({ ...prevData, [name]: value }))
    setInputErrors((prevErrors) => ({ ...prevErrors, [name]: '' }))
  }

  const handleThumbnailChange = (newThumbnail) => {
    setThumbnail(newThumbnail)
  }

  const handleImagesChange = (newImages) => {
    setImages(newImages)
  }

  const handleDocumentsChange = (newDocuments) => {
    setDocuments(newDocuments)
  }

  useEffect(() => {
    setFormState(prevState => ({
      ...prevState,
      saleMaxBuy: prevState.tokenTotalSupply,
    }))
  }, [formState.tokenTotalSupply])

  const handleSubmit = async (event) => {
    event.preventDefault()
    setError('')

    if (!isFormValid(formState)) {
      return
    }
    setIsLoading(true)

    formState.saleMaxBuy

    try {
      const response = await TokenSaleService.createTokenSaleRequest(
        account,
        formState,
        musharakahAllowed,
        isRefinancing,
        thumbnail,
        images,
        documents,
      )
      console.log(response)
      navigate('/request-tokensale-success')
    } catch (error) {
      console.log(error)
      setError(JSON.stringify(error))
    } finally {
      setIsLoading(false)
    }
  }

  const isWalletConnected = useIsWalletConnected()

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.commonForm}>
          {!isWalletConnected ? (
            <h3>Please connect your wallet to proceed.</h3>
          ) : (
            <div className={styles.mainSection}>
              <p className={styles.header}>Main Information</p>
              <p className={styles.description}>
                It is a long established fact that a reader will be distracted
                by the readable content of a page when looking at its layout.
              </p>
              <div className={styles.formSection}>
                <form autoComplete='off' onSubmit={handleSubmit}>
                  <div
                    onClick={() => setMusharakahAllowed(false)}
                    className={`${styles.selector} ${
                      !musharakahAllowed ? styles.active : ''
                    }`}
                  >
                    <p className={styles.selectorTitle}>Standard tokensale</p>
                    <p className={styles.selectorDesc}>
                      Enables investors to profit from rent and appreciation.
                      {' '}
                      <a href="#">Read more</a>
                    </p>
                  </div>
                  <div
                    onClick={() => setMusharakahAllowed(true)}
                    className={`${styles.selector} ${
                      musharakahAllowed ? styles.active : ' '
                    }`}
                  >
                    <p className={styles.selectorTitle}>Musharakah tokensale</p>
                    <p className={styles.selectorDesc}>
                      Standard + allows interested investors to gain full
                      ownership by eventually redeeming all tokens.
                      {' '}
                      <a href="#">Read more</a>
                    </p>
                  </div>
                  <CustomInputUi
                    label="Title"
                    name="title"
                    value={formState.title}
                    placeholder="Title"
                    onChange={handleInputChange}
                    disabled={isLoading}
                    error={inputErrors.title}
                    autoComplete="off"
                    hint="Example: Amazing property In Paris"
                  />

                  <CustomInputUi
                    label="Description"
                    name="description"
                    value={formState.description}
                    placeholder="Description"
                    onChange={handleInputChange}
                    disabled={isLoading}
                    error={inputErrors.description}
                    autoComplete="off"
                    hint="Example: Very amazing property! You will not regret!"
                  />

                  <CustomInputUi
                    label="Address"
                    name="address"
                    value={formState.address}
                    placeholder="Address"
                    onChange={handleInputChange}
                    disabled={isLoading}
                    error={inputErrors.address}
                    autoComplete="off"
                    hint="Example: St. Lui 9"
                  />

                  <CustomInputUi
                    label="City"
                    name="city"
                    value={formState.city}
                    placeholder="City"
                    onChange={handleInputChange}
                    disabled={isLoading}
                    error={inputErrors.city}
                    autoComplete="off"
                    hint="Example: Paris"
                  />

                  <CustomInputUi
                    label="State"
                    name="country"
                    value={formState.country}
                    placeholder="State"
                    onChange={handleInputChange}
                    disabled={isLoading}
                    error={inputErrors.country}
                    autoComplete="off"
                    hint="Example: California"
                  />

                  <CustomInputUi
                    label="Proposed Sale Price"
                    name="proposedSalePrice"
                    value={formState.proposedSalePrice}
                    placeholder="Proposed Sale Price"
                    onChange={handleInputChange}
                    disabled={isLoading}
                    error={inputErrors.proposedSalePrice}
                    autoComplete="off"
                    hint="Enter the amount in dollars"
                  />

                  {/* <CustomInputUi
                  label="Maximum number of tokens for purchase"
                  name="saleMaxBuy"
                  value={formState.saleMaxBuy}
                  placeholder="Max amount"
                  onChange={handleInputChange}
                  disabled={isLoading}
                  error={inputErrors.saleMaxBuy}
                  autoComplete="off"
                  hint="Example: 1"
                /> */}

                  <CustomInputUi
                    label="TokenSale start date"
                    name="saleStartAt"
                    value={formState.saleStartAt}
                    placeholder=""
                    onChange={handleInputChange}
                    disabled={isLoading}
                    error={inputErrors.saleStartAt}
                    type="date"
                    autoComplete="off"
                    hint="Example: 2023-12-02"
                  />

                  <CustomInputUi
                    label="TokenSale end date"
                    name="saleEndAt"
                    value={formState.saleEndAt}
                    placeholder=""
                    onChange={handleInputChange}
                    disabled={isLoading}
                    error={inputErrors.saleEndAt}
                    type="date"
                    autoComplete="off"
                    hint="Example: 2023-12-02"
                  />

                  {/* Are you refinancing? */}
                  <div className={styles.refinanceSection}>
                    <p className={styles.refinanceLabel}>
                      Are you refinancing?
                    </p>
                    <div className={styles.refinanceOptions}>
                      <label>
                        <input
                          type="radio"
                          name="refinance"
                          value="yes"
                          onChange={() => setIsRefinancing(true)}
                          checked={isRefinancing}
                        />
                        Yes
                      </label>
                      <label>
                        <input
                          type="radio"
                          name="refinance"
                          value="no"
                          onChange={() => setIsRefinancing(false)}
                          checked={!isRefinancing}
                        />
                        No
                      </label>
                    </div>
                    {isRefinancing && (
                      <CustomInputUi
                        label="House equity percentage already owned"
                        name="houseEquityOwned"
                        value={formState.houseEquityOwned || ''}
                        placeholder="Enter percentage of house equity owned"
                        onChange={handleInputChange}
                        disabled={isLoading}
                        error={inputErrors.houseEquityOwned}
                        autoComplete="off"
                        hint="Example: 1 - 100 without %"
                      />
                    )}
                  </div>

                  <ThumbnailUploader
                    onThumbnailChange={handleThumbnailChange}
                  />

                  <ImagesUploader onImagesChange={handleImagesChange} />

                  <DocumentsUploader
                    onDocumentsChange={handleDocumentsChange}
                  />

                  <hr className={styles.separator} />
                  {Object.values(inputErrors).some((value) => value !== '') && (
                    <div className={styles.errorWrapper}>
                      Correct the errors mentioned above
                    </div>
                  )}

                  {error && <div className={styles.errorWrapper}>{error}</div>}
                  <button
                    className={styles.submitBtn}
                    type='submit'
                    disabled={isLoading}
                  >
                    {isLoading ? 'Creating...' : 'Create TokenSale Request'}
                  </button>
                </form>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default RequestTokenSale
