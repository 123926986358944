import { ChainId } from 'web3/chains'

export const ADDRESS_REGISTRY_ADDRESS = {
  [ChainId.EDIFICE]: '0x9F8885CDb7f19491Cf5995743d151ccD7b599006',
  [ChainId.TESTNET]: '0x1f5FB68065f7d8A6a1f1751eEf94d3c5c66Ce4c7',
  [ChainId.LOCAL]: '0xDc64a140Aa3E981100a9becA4E685f962f0cF6C9',
}

export const EXCHANGE_ADDRESS = {
  [ChainId.EDIFICE]: '0x828ac751080a313D71769bD654Ce76eEceEa8B44',
  [ChainId.EDIFICE_TESTNET]: '0x8Ee071caFfbD213D3373de62761e6e71de4c83e8',
  [ChainId.LOCAL]: '0xB7f8BC63BbcaD18155201308C8f3540b07f84F5e',
}